import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-077103b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-list-view-media" }
const _hoisted_2 = { class: "has-background-modest level-center is-mobile" }
const _hoisted_3 = { class: "image-list-view-content" }
const _hoisted_4 = { class: "image-list-view-content-title has-text-table-cell-title" }
const _hoisted_5 = { class: "image-list-view-tools level is-mobile" }
const _hoisted_6 = { class: "image-list-view-tools-left level is-mobile" }
const _hoisted_7 = { class: "has-text-link" }
const _hoisted_8 = {
  key: 0,
  class: "has-text-link"
}
const _hoisted_9 = { class: "image-list-view-tools-right has-text-link level is-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PictureImg = _resolveComponent("PictureImg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ButtonIcon = _resolveComponent("ButtonIcon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), { class: "image-list-view has-background-contents" }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, {
        to: _ctx.getDetailLink(_ctx.content)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("figure", _hoisted_2, [
              _createVNode(_component_PictureImg, {
                src: `${_ctx.pictureUrl}?pid=${_ctx.content['pid'].replace(
              'info:ndljp/pid/',
              ''
            )}&bid=${_ctx.content['bid']}&cid=${_ctx.content['cid']}&x=${
              _ctx.content['x']
            }&y=${_ctx.content['y']}&w=${_ctx.content['w']}&h=${_ctx.content['h']}`,
                alt: `${_ctx.meta['0001Dtct'][0]}${_ctx.$t('spotlight.altSuffix')}`,
                id: _ctx.item.id
              }, null, 8, ["src", "alt", "id"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.meta["0001Dtct"][0]), 1)
          ])
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ButtonIcon, {
              "data-cy": "image-list-view-tools-search-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchImage(_ctx.item.id))),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.searchImage(_ctx.item.id)), ["prevent"]), ["space","enter"])),
              icon: "magnifyingglass",
              styled: "is-none",
              "aria-label": _ctx.$t('message.doSearch')
            }, null, 8, ["aria-label"])
          ]),
          (_ctx.isDownloadable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_ButtonIcon, {
                  "data-cy": "image-list-view-tools-download-button",
                  onClick: _ctx.download,
                  onKeydown: _withKeys(_withModifiers(_ctx.download, ["prevent"]), ["space","enter"]),
                  icon: "action-download",
                  styled: "is-none",
                  "aria-label": _ctx.$t('label.download')
                }, null, 8, ["onClick", "onKeydown", "aria-label"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ButtonIcon, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeItem(_ctx.content.pid))),
            onKeydown: _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (_ctx.removeItem(_ctx.content.pid)), ["prevent"]), ["space","enter"])),
            icon: "minus-circle",
            styled: "is-none",
            "aria-label": _ctx.$t('message.excludeItem'),
            class: "has-text-modest",
            "data-cy": "image-list-view-tools-remove-item-button"
          }, null, 8, ["aria-label"])
        ])
      ])
    ]),
    _: 1
  }))
}