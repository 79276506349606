
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'PictureImg',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const data = ref<any>(null)
    fetch(props.src, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((myBlob) => {
        data.value = URL.createObjectURL(myBlob)
      })
    return {
      data,
    }
  },
})
