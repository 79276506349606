import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8817ab0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "the-search-result-main the-search-image-result-main",
  "aria-live": "polite"
}
const _hoisted_2 = { class: "the-search-result-main-header" }
const _hoisted_3 = { class: "the-search-result-main-condition is-hidden-mobile level-left flex-wrap" }
const _hoisted_4 = {
  class: "has-text-title has-text-contents-title",
  id: "search-result-total-hits-message"
}
const _hoisted_5 = { id: "itemTotalHits" }
const _hoisted_6 = {
  key: 0,
  class: "search-not-found"
}
const _hoisted_7 = {
  key: 1,
  class: "the-search-result-main-posts thumbnail-view"
}
const _hoisted_8 = { class: "the-search-result-main-show-facet level-right is-mobile is-hidden-desktop has-background-contents" }
const _hoisted_9 = { class: "the-search-result-main-item-list" }
const _hoisted_10 = {
  key: 1,
  class: "loading_icon_holder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_ImageListView = _resolveComponent("ImageListView")!
  const _component_IconLoading = _resolveComponent("IconLoading")!

  return (!_ctx.isProcessing)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, [
              _createTextVNode(_toDisplayString(_ctx.$t('label.searchResult')) + " ", 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.toLocale(_ctx.total, _ctx.lang)), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('label.case')), 1)
            ])
          ])
        ]),
        (_ctx.total == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('label.notFound')), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_AppButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal())),
                  class: "button-normal",
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.showModal()), ["prevent"]), ["space","enter"])),
                  styled: "is-normal",
                  id: "sorting-button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('parts.facetFiltering')), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemList, (item) => {
                  return (_openBlock(), _createBlock(_component_ImageListView, {
                    item: item,
                    key: item.id,
                    tag: "article",
                    class: "the-search-result-main-item-article",
                    "display-mode": "thumbnail"
                  }, null, 8, ["item"]))
                }), 128))
              ])
            ]))
      ]))
    : (_openBlock(), _createElementBlock("section", _hoisted_10, [
        _createVNode(_component_IconLoading, { style: {
        width: '4rem',
        height: '4rem',
      } })
      ]))
}