
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import PictureImg from '../atoms/PictureImg.vue'
import PlaceholderImage from '../atoms/PlaceholderImage.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'
import { ItemDownloadRequest } from '@/data/@types/ItemDownloadRequest'
import { ContentsBundle } from '@/data/@types/ContentsBundle'
import { Content } from '@/data/@types/Content'
import { useI18n } from 'vue-i18n'
import { Picture } from '@/data/@types/picture'
import makeUpdatedPidString from '@/domain/item/itemViewer/makeUpdatedPidString'
import { postData } from '@/helpers/util/webApiUtil'
import { downloadContents } from '@/domain/item/itemViewer/contentsDownload'
import { DownloadType } from '@/data/@types/DownloadType'

const isServer = typeof window === 'undefined'
const BASE_URL = process.env.VUE_APP_API_BASE_URL

export default defineComponent({
  name: 'ImageListView',
  components: {
    PictureImg,
    PlaceholderImage,
    ButtonIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  setup (props) {
    const isDownloadable = ref(false)
    const checkDownloadable = async () => {
      const contents: string[] = await postData(`${BASE_URL}/get/allowedContents/download`, {
        pid: props.item.content.pid,
        bid: props.item.content.bid,
        cid: [props.item.content.cid],
      })
      isDownloadable.value = contents.includes(props.item.content.cid)
    }
    checkDownloadable()

    const router = useRouter()
    const store = useStore()
    const i18n = useI18n()
    const route = useRoute()

    const bookmarked = ref(false)

    const bookmarkItem = () => {
      bookmarked.value = !bookmarked.value
    }

    const searchImage = (id: any) => {
      if (!isServer) {
        const img: any = document.querySelector(`.app-img-${id}`)
        const canvas: any = document.createElement('canvas')
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        canvas.getContext('2d').drawImage(img, 0, 0)

        canvas.toBlob(
          async (blob: any) => {
            await store.dispatch('uploadFile', blob)
            router.push({
              name: 'SearchImageResult',
              query: {
                image: store.getters?.PictureFile.key,
              },
            })
          },
          'image/jpeg',
          1.0
        )
      }
    }

    const getDetailLink = (content: any) => {
      const contentsBundleIndex = content.item.contentsBundles
        .findIndex((v: ContentsBundle) => v.id === content.bid)
      if (contentsBundleIndex === -1) return `/${makeUpdatedPidString(content.pid)}`
      const contentIndex = content.item.contentsBundles[contentsBundleIndex].contents
        .findIndex((v: Content) => v.id === content.cid)
      if (contentIndex === -1) return `/${makeUpdatedPidString(content.pid)}/${contentsBundleIndex + 1}`
      return `/${makeUpdatedPidString(content.pid)}/${contentsBundleIndex + 1}/${contentIndex + 1}`
    }

    const download = async () => {
      const item = {
        pid: props.item.content.pid.replace('info:ndljp/pid/', ''),
        bid: props.item.content.bid,
        cid: [props.item.content.cid],
        all: false,
        toc: [],
        // TODO ファイル名の整理
        fileName: `digidepo_${props.item.content.id}`,
        title: '',
        author: '',
        keyword: '',
      }
      const option = {
        type: 'jpg',
        size: 70,
        function: 'download',
        filter: {
          brightness: 0,
          contrast: 0,
          sharpness: 0,
          gamma: 1,
          degree: 0,
        },
        pattern: [1, 1],
        frame: {
          x: props.item.content.x,
          y: props.item.content.y,
          w: props.item.content.w,
          h: props.item.content.h,
        },
        trimming: [{
          Left: props.item.content.x,
          Right: props.item.content.x + props.item.content.w,
          Top: props.item.content.y,
          Bottom: props.item.content.y + props.item.content.h,
        }],
      }
      const downloadParam: ItemDownloadRequest = {
        item: item,
        option: option,
      }
      store.commit('SET_PICTURE_META', { picture: props.item.content })
      const itemTitle = props.item.content.item.meta['0001Dtct'][0]
      downloadContents(store, downloadParam, itemTitle, i18n, DownloadType.PICTURE)
    }

    const removeItemList = computed(() => {
      const pidList: string[] = []
      const removeItems = store.getters.RemoveItems
      removeItems.forEach((v: Picture) => pidList.push(v.pid))
      return pidList
    })

    const removeItem = (pid: string) => {
      store.commit('ADD_REMOVEITEM_LIST', { pid: pid, title: props.item.content.item.meta['0001Dtct'][0] })

      const where = {
        ...route.query,
        pageNum: '0',
        excludes_pid_list: removeItemList.value,
      }

      router.push({ query: where })
    }

    return {
      isDownloadable,
      meta: props.item.content.item.meta,
      content: props.item.content,
      pictureUrl: process.env.VUE_APP_PICTURE_API_URL,
      bookmarked,
      bookmarkItem,
      searchImage,
      getDetailLink,
      removeItem,
      download,
    }
  },
})
