
import { ref, defineComponent, onMounted, watchEffect, computed, reactive, watch } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import {
  useRoute,
  LocationQuery,
  LocationQueryValue,
  onBeforeRouteUpdate,
} from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import toLocale from '@/helpers/util/toLocale'
import ImageListView from './ImageListView.vue'
import { ImageSearchRequest } from '@/data/@types/ImageSearchRequest'
import IconLoading from '@/components/atoms/IconLoading.vue'

const isServer = typeof window === 'undefined'
const PageSize = 9

type LocationQueryValueNotNull = LocationQueryValue & string;

type ImageResultQuery = LocationQuery & {
  ['keyword']: LocationQueryValue;
  ['image']: LocationQueryValueNotNull;
  ['collection_facet']: LocationQueryValue | LocationQueryValue[];
  ['permission_facet']: LocationQueryValue | LocationQueryValue[];
  ['levelCode_facet']: LocationQueryValue | LocationQueryValue[];
  ['fromYear_facet']: LocationQueryValue | LocationQueryValue[];
  ['toYear_facet']: LocationQueryValue | LocationQueryValue[];
  ['excludes_pid_list']: LocationQueryValue | LocationQueryValue[];
  ['color_facet']: LocationQueryValue | LocationQueryValue[];
};

const createInitRequest = (query: ImageResultQuery): ImageSearchRequest => {
  return {
    key: query.image,
    keyword: query.keyword?.toString(),
    collection_facet:
      query.collection_facet && query.collection_facet.length
        ? [...query.collection_facet.toString().split(',')]
        : [],
    permission_facet:
      query.permission_facet && query.permission_facet.length
        ? [...query.permission_facet.toString().split(',')]
        : [],
    levelCode_facet:
      query.levelCode_facet && query.levelCode_facet.length
        ? [...query.levelCode_facet.toString().split(',')]
        : [],
    fromYear_facet: query.fromYear_facet ? query.fromYear_facet.toString() : '',
    toYear_facet: query.toYear_facet ? query.toYear_facet.toString() : '',
    excludes_pid_list: query.excludes_pid_list && query.excludes_pid_list.length
      ? [...query.excludes_pid_list.toString().split(',')]
      : [],
    color_facet:
      query.color_facet && query.color_facet.length
        ? [...query.color_facet.toString().split(',')]
        : [],
  }
}

export default defineComponent({
  components: {
    ImageListView,
    IconLoading,
    AppButton,
  },
  setup (props, context) {
    context.emit('closeModal') // ファセット選択時に非表示にする(正常に動いていない)
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const reqest = ref<ImageSearchRequest>({
      key: '',
    })
    const page = ref<number>(1)
    const itemList = ref<any>([])
    const isProcessing = computed(() => store.getters.PictureIsProcessing)
    const total = computed(() => store.getters.totalHits)

    // TODO refactor ページネーション対応時にsearchResultState.tsへ色々移管する
    const state = reactive({
      showModal: false,
    })

    watchEffect(() => {
      if (
        store.getters.PictureIds.length *
          store.getters.ImageList.searchHits.length >
        0
      ) {
        const sortedVald = store.getters.PictureIds.map((id: string) =>
          store.getters.ImageList.searchHits.find((hit: any) => hit.id === id)
        ) // valdの返戻でソート（近い順に並べる）
        const items = sortedVald
          .filter((item: any) => item?.id)
          .slice(PageSize * (page.value - 1), PageSize * page.value)
        itemList.value = [...itemList.value, ...items]
        const headerSiteName = document.getElementById('the-global-header-site-name') as HTMLElement
        headerSiteName.focus()
      }
    })

    const search = (req: ImageSearchRequest) => {
      store.dispatch('imageSearch', {
        request: {
          ...reqest.value,
          key: store.getters.PictureFile.key || req.key,
        },
        errorMessage: i18n.t('message.pictureSearchError'),
      })
    }

    watch(route, () => {
      // TheSearchResultMainコンポーネントはファセット選択時に再描画されることでモーダルが非表示になっているが
      // TheSearchImageResultMainコンポーネントでは再描画されないため、パスの変更を見てモーダルを非表示にする
      context.emit('closeModal')
    })

    onMounted(() => {
      store.dispatch('clear')
      const request = createInitRequest(route.query as ImageResultQuery)
      reqest.value = request
      console.log('onMounted...', request)
      itemList.value = []
      search(request)

      const moreRead = (scrollPos: number) => {
        if (!isServer) {
          const currentPosition = window.innerHeight + scrollPos // 現在のウィンドウの高さ + 垂直スクロール量
          const images: any = Array.from(document.querySelectorAll('.app-img')) // 表示されている画像のリスト
          const size = images.length
          const limitPosition = images[size - 1]?.offsetTop // 最後の画像の垂直座標
          if (limitPosition + 50 < currentPosition) {
            page.value++
          }
        }
      }

      if (!isServer) {
        // スクロールイベントを抑制
        // https://developer.mozilla.org/ja/docs/Web/API/Document/scroll_event
        let lastKnownScrollPosition = 0
        let ticking = false

        window.addEventListener('scroll', function (e) {
          lastKnownScrollPosition = window.scrollY

          if (!ticking) {
            window.requestAnimationFrame(function () {
              moreRead(lastKnownScrollPosition)
              ticking = false
            })

            ticking = true
          }
        })
        const headerSiteName = document.getElementById('the-global-header-site-name') as HTMLElement
        headerSiteName.focus()
      }
    })

    onBeforeRouteUpdate((to, _) => {
      store.dispatch('clear')
      page.value = 1
      itemList.value = []
      const request = createInitRequest(to.query as ImageResultQuery)
      console.log('onBeforeRouteUpdate...', request)
      reqest.value = request
      search(request)
    })

    const showModal = () => {
      context.emit('showModal')
      state.showModal = true
      if (!isServer) {
        const focusElement = document.getElementById('modal-focus-target')
        if (focusElement) {
          focusElement.focus()
        }
      }
    }

    return {
      state,
      total,
      itemList,
      isProcessing,
      toLocale,
      lang,
      showModal,
    }
  },
})
