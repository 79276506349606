
import { useI18n } from 'vue-i18n'
import { defineComponent, computed, reactive, watch, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import ItemListView from './ItemListView.vue'
import PageNavigation from '../molecules/PageNavigation.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import AppSelect from '../atoms/AppSelect.vue'
import { displayList } from '../../data/selector/displayList'
import { displayModeList } from '../../data/selector/displayModeList'
import { sortList } from '@/data/selector/sortList'
import CloserController from '../molecules/CloserController.vue'
import RadioButtonList from '../molecules/RadioButtonList.vue'
import { getSelector } from '@/data/selector/getSelector'
import toLocale from '@/helpers/util/toLocale'
import { scrollToSearchResultTop } from '@/helpers/util/scrollUtil'

export default defineComponent({
  components: {
    ItemListView,
    PageNavigation,
    AppButton,
    AppSelect,
    CloserController,
    RadioButtonList,
  },
  props: {
    modelValue: {
      type: Set,
      default: new Set(),
      required: false,
    },
    pageNo: {
      type: String,
      required: true,
    },
    collectionId: {
      type: String,
      required: false,
    },
  },
  setup (props, context) {
    context.emit('closeModal')
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const lang = i18n.locale
    const searchMode = computed(() => store.getters.searchMode)
    const isServer = typeof window === 'undefined'

    // TODO refactor ページネーション対応時にsearchResultState.tsへ色々移管する
    const state = reactive({
      displayedItemCount: searchMode.value ? Number(searchMode.value.pageSize) : 20,
      displayMode: searchMode.value ? searchMode.value.displayMode : 'list',
      sort: searchMode.value ? searchMode.value.sortKey : 'SCORE',
      showModal: false,
      showSortList: true,
      showDisplayList: true,
    })

    const displayLocale = computed(() => getSelector('displayList', displayList, i18n.t))
    const displayedItemCount = computed({
      get: () => state.displayedItemCount,
      set: (val) => {
        searchMode.value.pageSize = val
        store.dispatch('selectDisplayCount', val)
        router.push({
          query: {
            ...route.query,
            pageSize: val,
            pageNum: '0',
          },
        })
      },
    })
    const displayModeLocale = computed(() => getSelector('displayModeList', displayModeList, i18n.t))
    const displayMode = computed({
      get: () => state.displayMode,
      set: (val) => {
        searchMode.value.displayMode = val
        store.dispatch('selectDisplayMode', val)
        router.push({
          query: {
            ...route.query,
            displayMode: val,
          },
        })
        store.commit('UPDATE_SEARCH_STATE', false)
      },
    })

    const sortLocale = computed(() => getSelector('sortList', sortList, i18n.t))

    const sort = computed({
      get: () => state.sort,
      set: (val) => {
        searchMode.value.sortKey = val
        store.dispatch('selectSortKey', val)
        router.push({
          query: {
            ...route.query,
            sortKey: val,
            pageNum: '0',
          },
        })
        store.commit('UPDATE_SEARCH_STATE', false)
      },
    })
    const pageNumber = computed({
      get: () => Number(route.query.pageNum ? route.query.pageNum : 0),
      set: (val) => {
        router.push({
          query: {
            ...route.query,
            pageNum: (val).toString(),
          },
        })
        store.commit('UPDATE_SEARCH_STATE', false)
      },
    })

    const total = computed(() => {
      return store.getters.totalHits
    })

    const start = computed(() => {
      return pageNumber.value * state.displayedItemCount
    })

    const end = computed(() => {
      // (仮決め)201件ヒット時想定外のページ指定したときに'221-201'ではなく'221-240'とだすため
      if (start.value > store.getters.totalHits) {
        return start.value + state.displayedItemCount
      }
      return Math.min(start.value + state.displayedItemCount, store.getters.totalHits)
    })

    const itemList = computed(() => {
      return store.getters.ItemList
    })

    const hasError = computed(() => {
      return store.getters.hasErrorInSearchResultPage
    })

    const pageNavigationIndex = computed(() => {
      const pageCount = Math.ceil(Math.min(store.getters.totalHits, 10000) / state.displayedItemCount)
      return Array(pageCount).fill(0).map((_, i) => (i + 1).toString())
    })

    onMounted(() => {
      if (isServer) return
      const headerSiteName = document.getElementById('the-global-header-site-name') as HTMLElement
      headerSiteName.focus()
      if (!store.getters.isFirstTransition) {
        // 表示順・表示件数・表示パターンの変更、ファセットでの絞り込み時のみ
        scrollToSearchResultTop()
      }
    })

    const showModal = () => {
      context.emit('showModal')
      state.showModal = true
      if (!isServer) {
        const focusElement = document.getElementById('modal-focus-target')
        if (focusElement) {
          focusElement.focus()
        }
      }
    }

    return {
      itemList,
      state,
      pageNumber,
      pageNavigationIndex,
      displayModeLocale,
      displayLocale,
      displayedItemCount,
      sortLocale,
      sort,
      total,
      start,
      end,
      hasError,
      displayMode,
      lang,
      toLocale,
      showModal,
    }
  },
})
